<template>
  <LoadingBig v-if="loading" class="w-screen h-screen absolute" />
  <div v-else id="main" class="px-5">
    <div class="flex items-center justify-center">
      <div>
        <div class="flex justify-center my-5">
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.2" cx="28" cy="28" r="28" fill="#79EAA8" />
            <circle cx="28" cy="28" r="18" fill="white" />
            <g clip-path="url(#clip0_620_14069)">
              <path
                d="M28 29C30.21 29 32 27.21 32 25C32 22.79 30.21 21 28 21C25.79 21 24 22.79 24 25C24 27.21 25.79 29 28 29ZM28 31C25.33 31 20 32.34 20 35V37H36V35C36 32.34 30.67 31 28 31Z"
                fill="white" />
              <g clip-path="url(#clip1_620_14069)">
                <path d="M24.9999 31.17L20.8299 27L19.4099 28.41L24.9999 34L36.9999 22L35.5899 20.59L24.9999 31.17Z"
                  fill="#30C46F" />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_620_14069">
                <rect width="24" height="24" fill="white" transform="translate(16 17)" />
              </clipPath>
              <clipPath id="clip1_620_14069">
                <rect width="24" height="24" fill="white" transform="translate(16 15)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="font-[IBM500]">
          <h1 class="text-[20px] text-[#333333] my-3">
            {{
              {
                denuncia: "Denúncia",
                solicitacao: "Solicitação",
                sugestao: "Dúvida ou sugestão",
              }[this.$route.params.type]
            }}
            recebida
          </h1>
          <p class="text-[14px] text-[#676767]">
            Guarde o código de acompanhamento abaixo em local seguro. Consulte-o
            para verificar seu andamento.
          </p>
          <div
            class="flex justify-center items-center rounded-md text-white text-[IBM500] bg-[#30C46F] px-5 py-3 my-3 cursor-pointer"
            v-clipboard="protocol">
            {{ protocol }}
          </div>
          <div class="flex justify-center items-center cursor-pointer" v-clipboard="protocol">
            <div class="mr-2">
              <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.9 5C1.9 3.29 3.29 1.9 5 1.9H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8.1H5C3.29 8.1 1.9 6.71 1.9 5ZM6 6H14V4H6V6ZM15 0H11V1.9H15C16.71 1.9 18.1 3.29 18.1 5C18.1 6.71 16.71 8.1 15 8.1H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0Z"
                  fill="#4BDD89" />
              </svg>
            </div>
            <div class="text-[#676767] text-[14px]">
              Clique para copiar o número
            </div>
          </div>
        </div>

        <div class="font-[Roboto700]">
          <ButtonOutlined icon="letter" @click="showInput()" v-if="!show && !emailSent">ENVIAR PARA MEU EMAIL</ButtonOutlined>

          <!-- Exibir o input e o botão de envio apenas se o email ainda não foi enviado -->
          <div v-if="show && !emailSent">
            <InputComponent placeholder="Escreva seu email" v-model="email" :required="!isEmailValid"></InputComponent>
            <ButtonOutlined icon="letter" @click="send()" :disabled="!isEmailValid">ENVIAR</ButtonOutlined>
          </div>

          <!-- Exibir mensagem de sucesso após envio -->
          <p v-if="emailSent" class="mt-10 font-[IBM500] text-[#30C46F]">Email enviado com sucesso</p>

          <a :href="`https://${VUE_APP_ENV === 'hml' ? 'hml.' : ''}bcompliance.com.br/empresas/${$route.params.id}`"
            target="_blank" v-if="$route.params.id !== '67a510a30b7271de8fd3d26e'">
            <ButtonOutlined icon="hamburguer-check">ACOMPANHAR</ButtonOutlined>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonOutlined from "@/components/ButtonOutlined.vue";
import InputComponent from "@/components/InputComponent.vue";
import LoadingBig from "@/components/LoadingBig.vue";

export default {
  data() {
    return {
      protocol: null,
      show: false,
      email: "",
      emailSent: false, // Flag para verificar se o email foi enviado,
      loading: false,
    };
  },
  components: {
    ButtonOutlined,
    InputComponent,
    LoadingBig,
  },
  computed: {
    isEmailValid() {
      // Regex simples para validar emails
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
  },
  methods: {
    showInput() {
      this.show = true;
    },
    async send() {
      if (!this.isEmailValid) return;
      this.loading = true;
      try {
        const companyId = this.$route.params.companyId;
        const type = this.$route.params.type;
        await this.axios.post("/public/email", {
          hash: this.protocol,
          email: this.email,
          company: companyId,
          type: {
            denuncia: "complaint",
            solicitacao: "request",
            sugestao: "suggestion",
          }[type],
        });

        // Marcar que o email foi enviado
        this.emailSent = true;
        this.show = false;
      } catch (error) {
        this.toast(
          "Desculpe, ocorreu um erro com os nossos servidores.",
          "error",
          this.$toast
        );
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    try {
      this.protocol = "##################";
      const requestString = this.$route.params.request;
      if (requestString) {
        const request = JSON.parse(requestString);
        this.protocol = request.protocol;
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
