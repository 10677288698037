<template>
  <!-- TODO: podemos usar <component is="" /> para selecionar ao invez de usar o v-if? -->
  <!-- TODO: colocar o radio e o select pelo menos dentro de arquivos (componente) -->
  <CompanyWrapper>
    <div v-if="fields.length > 0 && !sending">
      <div v-for="field of fields" :key="field.id" class="py-2">
        <div :class="{ hidden: !field.show }" v-if="field.type === 'message'">
          <div
            class="font-[IBM500] text-[20px] text-center py-2 text-[#333333]"
          >
            {{ field.title }}
          </div>
          <div
            class="font-[IBM500] text-[14px] text-[#676767]"
            :class="`text-${field.align ? field.align : 'center'}`"
          >
            {{ field.description }}
          </div>
        </div>

        <div
          class="flex justify-center items-center py-5"
          :class="{ hidden: !field.show }"
          v-if="field.type === 'image'"
        >
          <div v-html="field.url"></div>
        </div>

        <div
          :class="{ hidden: !field.show }"
          v-if="field.type === 'radio'"
          class="text-left"
        >
          <div class="font-[IBM600] text-[16px] text-[#333333]">
            {{ field.question }}
          </div>
          <div class="grid grid-cols-2">
            <div v-for="radio of field.set" :key="radio.option" class="flex">
              <input
                class="font-[IBM500] text-[16px] text-[#343434]"
                type="radio"
                :name="field.id"
                :value="radio.option"
                v-model="field.model"
                @click="setRadio($event.target.value, field)"
              />
              <div class="ml-2 flex justify-center items-center">
                {{ radio.option }}
              </div>
            </div>
          </div>
        </div>

        <div :class="{ hidden: !field.show }" v-if="field.type === 'input'">
          <div class="text-left font-[IBM600] text-[16px] text-[#333333]">
            {{ field.title }}
          </div>
          <InputComponent
            :id="field.id"
            :required="field?.required"
            :placeholder="field.placeholder"
            v-model="field.model"
          ></InputComponent>
          <div class="text-left font-[IBM400] text-[14px] text-[#343434]">
            {{ field.tip }}
          </div>
        </div>

        <div :class="{ hidden: !field.show }" v-if="field.type === 'date'">
          <div class="text-left font-[IBM600] text-[16px] py-2 text-[#333333]">
            {{ field.title }}
          </div>
          <InputComponent
            :required="field?.required"
            :placeholder="field.placeholder"
            v-model="field.model"
            type="date"
          ></InputComponent>
          <div class="text-left font-[IBM400] text-[14px] text-[#343434]">
            {{ field.tip }}
          </div>
        </div>

        <div :class="{ hidden: !field.show }" v-if="field.type === 'file'">
          <UploadFile
            :label="field.title"
            v-model:names="field.model"
            v-model:files="field.files"
          />
        </div>

        <div :class="{ hidden: !field.show }" v-if="field.type === 'select'">
          <div>{{ field.question }}</div>
          <select
            @change="setOption($event.target.value, field)"
            v-model="field.model"
            :class="{
              'border-b border-b-[#9CBFFF]': !field.required,
              ' border-b border-b-red-400': field.required,
            }"
            class="bg-gray-200 rounded-sm w-full p-3 mt-3 font-[IBM500]"
          >
            <option
              v-for="item in field.set"
              :key="item.option"
              :value="item.option"
            >
              {{ item.option }}
            </option>
          </select>
          <div
            class="p-2 text-left font-[IBM400] text-[14px] text-[#343434] my-2"
          >
            {{ field.selectedTip }}
          </div>
        </div>

        <div :class="{ hidden: !field.show }" v-if="field.type === 'textarea'">
          <div class="text-left font-[IBM600] text-[16px] text-[#333333]">
            {{ field.title }}
          </div>
          <TextareaComponent
            :id="field.id"
            :name="field.name"
            v-model="field.model"
            :placeholder="field.placeholder"
            :required="field.required"
          />
        </div>

        <div
          class="lg:px-20 border-b border-gray-200 py-2"
          :class="{ hidden: !field.show }"
          v-if="field.type === 'hr'"
        ></div>
      </div>
      <div>
        <ButtonComponent :accept="canGo()" @click="send" :title="buttonLabel" />
      </div>
    </div>
    <LoadingBig v-else />
  </CompanyWrapper>
</template>

<script>
import CompanyWrapper from "@/components/CompanyWrapper.vue";
import InputComponent from "@/components/InputComponent.vue";
import UploadFile from "@/components/UploadFile.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import TextareaComponent from "@/components/TextareaComponent.vue";
import LoadingBig from "@/components/LoadingBig.vue";

import { companyStorage } from "@/storage/company.js";
import { descriptions } from "@/storage/descriptions.js";
import { items } from "@/storage/items.js";

export default {
  components: {
    CompanyWrapper,
    InputComponent,
    UploadFile,
    ButtonComponent,
    TextareaComponent,
    LoadingBig,
  },
  data() {
    return {
      company: companyStorage,
      description: companyStorage?.data?.requestFields?.description,
      items: items,
      descriptions: descriptions,
      fields: [],
      childs: [],
      buttonLabel: "",
      sending: false,
    };
  },
  watch: {
    "company.data"(company) {
      const data =
        company[
          `${
            {
              denuncia: "complaint",
              solicitacao: "request",
              sugestao: "suggestion",
            }[this.$route.params.type]
          }Fields`
        ];

      this.fields = data.fields;
      this.buttonLabel = data.button;

      // Collect all child fields from all form fields that have set options
      let allChildFields = [];

      this.fields.forEach((field) => {
        field.show = true;
        if (field?.set?.length) {
          // Get all childs from all options in the set
          let childFields = field.set
            .filter((item) => item?.childs?.length > 0)
            .flatMap((item) => item.childs)
            .filter((child) => this.isMongoObjectId(child));

          allChildFields.push(...childFields);
        }
      });

      allChildFields.forEach((child) => {
        const field = this.fields.filter((item) => item._id === child)[0];
        field.show = false;
      });
    },
  },
  methods: {
    canGo() {
      return true;
    },
    setOption(option, field) {
      const result = field.set.filter((item) => item.option === option)[0];
      field.selectedTip = result.tip;

      console.log(result);

      // Show child fields if selected
      if (result.childs) {
        result.childs.forEach((child) => {
          const childField = this.fields.find((item) => item._id === child);
          if (childField) {
            childField.show = true;
          }
        });
      }

      // Hide child fields of other options
      field.set.filter((item) => item.option !== option).forEach((item) => {
        const childFields = item.childs?.filter((child) => this.isMongoObjectId(child)) || [];
        childFields.forEach((child) => {
          const childField = this.fields.find((item) => item._id === child);
          if (childField) {
            childField.show = false;
          }
        });
      });
    },
    setRadio(option, field) {
      try {
        const selected = field.set.filter((item) => item.option === option)[0];

        let allChildFields = [];
        let childFields = selected?.childs
          ?.filter((child) => this.isMongoObjectId(child)) || [];

        allChildFields.push(...childFields);

        // Show fields
        allChildFields.forEach((child) => {
          const fieldToShow = this.fields.find((item) => item._id === child);
          if (fieldToShow) {
            fieldToShow.show = true;
          }
        });

        // Hide other fields
        field.set.filter((item) => item.option !== option).forEach((item) => {
          const childFields = item.childs?.filter((child) => this.isMongoObjectId(child)) || [];
          console.log(childFields);
          if (childFields.length > 0) {
            childFields.forEach((child) => {
              const childField = this.fields.find((item) => item._id === child);
              if (childField) {
                childField.show = false;
              }
            });
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async send() {
      try {
        this.sending = true;

        const type = this.$route.params.type;

        // remove os campos de mensagem (titúlos e textos) e imagem do formulario
        this.fields = this.filteredForm();

        const [fileField] = this.fields.filter(
          (field) => field.type === "file"
        );

        // gera o payload para a geração das urls para upload dos arquivos
        let payload = {
          companyId: this.company.id,
          fields: this.fields,
        };

        let createdContentId;

        // processar arquivos
        if (fileField?.model?.length > 0) {
          // Envio o content extraindo os nomes dos arquivos e gerando as urls pre-assinadas

          const responseURLs = await this.axios.post(
            `/v2/public/contents/urls`,
            payload
          );

          const { urls = [] } = responseURLs?.data || {};

          const awsURLsAndFileNames = urls;

          createdContentId = responseURLs?.data?.createdContentId;

          if (!createdContentId || awsURLsAndFileNames.length === 0) {
            throw new Error(
              "Houve algum erro no processamento dos arquivos em anexo"
            );
          }

          // TODO se não tem as informações necessários como _id (contentId) podemos parar e apresentar um erro

          const files = this.fields
            .filter((field) => {
              return field.type === "file";
            })
            .map((field) => {
              return field.files;
            })
            .flat();

          // Extraio as URLS da resposta

          const mapS3URLToFile = this.map(awsURLsAndFileNames, files);

          // Faço o upload de todos os arquivos
          const promisesS3 = mapS3URLToFile.map((element) => {
            return fetch(element.url, {
              method: "PUT",
              body: element.file[0],
            });
          });

          const responseUploadFilesToS3 = await Promise.all(promisesS3);

          // Se algum arquivo não foi abortamos o envio do conteúdo
          for (let responseFile of responseUploadFilesToS3) {
            if (responseFile.status < 200 || responseFile.status >= 300) {
              throw new Error(
                `Erro ao gravar ${this.getType(
                  type
                )}, por favor tente novamente. Status ${responseFile.status}`
              );
            }
          }
        }

        // Enviamos o content
        // o contentId é gerado ao gerar as URLs, para poder subir primeiro os arquivos por meio
        // das URLs do S3, em caso de não ter arquivos para subir o contentId é gerado no endpoint
        // que insere o conteudo

        payload.type = this.getType(type);
        if (createdContentId) payload.contentId = createdContentId;

        // salvar o novo conteudo depois de gravar os arquivos
        const responseContent = await this.axios.post(
          `/v2/public/contents`,
          payload
        );

        const companyId = this.$route.params.id;
        this.$router.push({
          name: "content-success",
          replace: true,
          params: {
            companyId,
            request: JSON.stringify(responseContent?.data),
          },
        });
      } catch (error) {
        const { message } = error.response?.data;
        this.toast(
          message?.join("\n") || error.message || "Erro desconhecido",
          "error",
          this.$toast,
          20000
        );
      } finally {
        this.sending = false;
      }
    },
    getType(type) {
      return {
        denuncia: "complaint",
        solicitacao: "request",
        sugestao: "suggestion",
      }[type];
    },
    filteredForm() {
      return this.fields.filter((field) => {
        if (["image", "message", "hr"].includes(field.type)) {
          return false;
        }
        if (field.model || field.required) {
          return true;
        }
        return false;
      });
    },
    map(awsURLSAndNames, files) {
      return awsURLSAndNames.map((urlAndName) => {
        const file = files.filter((file) => {
          return file.name === urlAndName.name;
        });
        return { url: urlAndName.url, file };
      });
    },
    isMongoObjectId(value) {
      return (
        typeof value === "string" &&
        value.length === 24 &&
        /^[0-9a-f]{24}$/.test(value)
      );
    },
  },
};
</script>
